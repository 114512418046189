<template>
  <tr :class="rowClass">
    <table-cell v-if="arHeaderKeys.includes('offer')" :label="$t('product')">
      <loading :loading="loading && !signed" absolute light local size="20" />
      <div class="d-flex align-center">
        <v-btn
          v-if="canAdd"
          :disabled="!validRow"
          class="mr-4"
          color="success"
          icon
          outlined
          x-small
          @click="onAddItem"
        >
          <icon-add />
        </v-btn>

        <v-btn
          v-else-if="canDelete && !signed"
          class="mr-4"
          color="error"
          icon
          outlined
          x-small
          @click="onDeleteItem"
        >
          <icon-remove />
        </v-btn>

        <product-select
          v-if="!signed && !exists"
          :key="`product.select.${iKey}`"
          ref="productSelector"
          v-model="product"
          :disabled="signed"
          :hide-details="!hint"
          :price-list-id="invoice.price_list_id"
          :sell="isSales"
          single-line
          without-generated
          item-text-code
          local
          @change="onSelectProduct"
        />
        <template v-else-if="obProduct || loading">
          <v-progress-circular
            v-if="loading"
            :width="3"
            color="primary"
            indeterminate
          />
          <product-select-or-create
            v-else-if="isEditable"
            :currency-id="item.get('currency_id')"
            :is-purchase="!isSales"
            :name="sName"
            :product="obProduct"
            :tax-type-id="item.get('tax_type_id')"
            hide-code
            @update:product="onChangeProduct"
          >
            <template v-if="isReceived && !isProcessed" #icon>
              <active-icon :active="!productIsTemporary" />
            </template>
          </product-select-or-create>
          <product-preview
            v-else
            :name="sName"
            :product="obProduct"
            hide-code
          />
        </template>
      </div>
    </table-cell>

    <table-cell v-if="arHeaderKeys.includes('name')" :label="$t('name')">
      <form-field-text
        v-model="sName"
        :disabled="signed"
        :hide-details="!hint"
        label="name"
        single-line
        @save="onAddItem"
      />
    </table-cell>

    <table-cell
      v-if="arHeaderKeys.includes('quantity')"
      :label="$t('quantity')"
    >
      <form-field-text
        :disabled="signed"
        :hide-details="!hint"
        :readonly="qtyMax === 1"
        :rules="qtyRules"
        :value="item.quantity"
        ref="quantityInput"
        full-width
        input-type="number"
        label="quantity"
        required
        single-line
        @save="onAddItem"
        @input:debounce="onChangeQty"
      />
    </table-cell>

    <table-cell v-if="arHeaderKeys.includes('price')" :label="$t('unit.price')">
      <unit-price-field
        v-if="offerCurrency || invoiceCurrency"
        :currency-id="offerCurrency ? offerCurrency.id : invoiceCurrency.id"
        :disabled="signed || !product"
        :hint="hint"
        :offer="offer"
        :price-with-tax="getPriceWithTax"
        :tax-type="obTaxType"
        :rules="unitPriceRules"
        :value="unitPrice"
        @price:reset="onResetPrice"
        @price:apply="onAddItem"
        @price:update="setUnitPrice"
      />
    </table-cell>

    <table-cell
      v-if="hasDiscountsPerLine && arHeaderKeys.includes('discounts')"
      :label="$t('discounts')"
    >
      <invoice-inline-discounts
        v-model="dialogInlineDiscounts"
        :disabled="disabled"
        :item="item"
        :price-with-tax="getPriceWithTax"
        :tax-percent="fTaxPercent"
        @update:discounts="applyInlineDiscounts"
      />
    </table-cell>

    <table-cell
      v-if="arHeaderKeys.includes('total_price_with_tax')"
      :label="$t('amount')"
    >
      <item-row-details
        v-model="dialogItemRowDetails"
        :disabled="disabled"
        :discount="sDiscount"
        :item="item"
        :subtotal="sSubtotal"
        :subtotal-without-tax="sSubtotalWithoutTax"
        :tax="sTaxPrice"
        :tax-type="obTaxType"
        :total="sTotal"
      />
    </table-cell>

    <table-cell v-if="arHeaderKeys.includes('tax_type')">
      <tax-type-preview :item="obTaxType" />
    </table-cell>

    <table-cell :label="$t('actions')" class="text-end">
      <invoice-labels-dialog
        v-if="product"
        v-model="dialogLabels"
        :position="item"
        :product="product"
        field="DscItem"
        hide-activator
      />
      <item-actions
        v-if="canAdd || canDelete"
        :can-add="canAdd"
        :can-delete="canDelete"
        :disabled="!validRow || signed"
        @item:add="onAddItem"
        @item:delete="onDeleteItem"
        @item:reset="reset"
      >
        <template #item="{ disabled, close }">
          <v-list-item
            v-if="hasDiscountsPerLine"
            :class="{ 'primary--text': !disabled }"
            :disabled="disabled"
            @click="openInlineDiscounts(close)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$t('discounts')" />
            </v-list-item-content>
            <v-list-item-icon>
              <icon-percent />
            </v-list-item-icon>
          </v-list-item>

          <v-list-item
            v-if="product"
            :class="{ 'primary--text': !disabled }"
            @click="openItemDetails(close)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$t('details')" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="product"
            :class="{ 'primary--text': !disabled }"
            @click="openLabelsDialog(close)"
          >
            <v-list-item-content>
              <v-list-item-title v-text="$t('assign.product.labels')" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </item-actions>
    </table-cell>
  </tr>
</template>

<script lang="ts">
import type {
  CompanySettingsData,
  CurrencyRate,
  CustomerData,
  DiscountData,
  OfferData,
  ProductGwData,
  TaxTypeData,
} from "@planetadeleste/vue-mc-gw";
import {
  CurrencyRates,
  Discount,
  Invoice,
  InvoiceMovementType,
  InvoicePosition,
  InvoiceType,
  TaxType,
  TaxTypeCollection,
} from "@planetadeleste/vue-mc-gw";
import {
  Component,
  Prop,
  Ref,
  VModel,
  Watch,
  Vue,
} from "vue-property-decorator";
import { Offer, Product } from "@planetadeleste/vue-mc-shopaholic";
import {
  convertCurrency,
  currencyConvert,
  currencyFormat,
  percent,
  reverseTax,
  round,
} from "@/plugins/helpers";
import { AppModule } from "@/store/app";
import { EventBus } from "@/services/event-bus";
import { InvoiceModule } from "@/store/invoice";

import ProductSelect from "@/modules/products/components/ProductSelect.vue";
import ProductPreview from "@/modules/products/components/ProductPreview.vue";
import InvoiceInlineDiscounts from "@/modules/invoices/components/discounts/InvoiceInlineDiscounts.vue";
import TableCell from "@/components/common/TableCell.vue";
import ItemActions from "@/modules/invoices/components/rows/ItemActions.vue";
import UnitPriceField from "@/modules/invoices/components/UnitPriceField.vue";
import ItemRowDetails from "@/modules/invoices/components/ItemRowDetails.vue";
import type { Callback } from "@/types/utils";
import { debug } from "@/composables/debug";
import {
  attempt,
  delay,
  first,
  forEach,
  get,
  isFunction,
  isNumber,
  map,
  set,
} from "lodash";
import { invalidItemPosition } from "@/modules/invoices/tools/utils";
import { number } from "mathjs";
import useMath from "@/composables/math";
import useProduct from "@/composables/product";
import dayjs from "dayjs";
import type { DataTableHeader } from "@/mixins/DataTableMixin";
import IconAdd from "@/components/icons/IconAdd.vue";
import IconRemove from "@/components/icons/IconRemove.vue";
import Loading from "@/components/common/loading/index.vue";
import type { DiscountDataExt } from "@/modules/invoices/components/discounts/InvoiceInlineItemDiscount.vue";
import InvoiceLabelsDialog from "@/modules/invoices/components/InvoiceLabelsDialog.vue";
import ProductSelectOrCreate from "@/modules/products/components/ProductSelectOrCreate.vue";
import ActiveIcon from "@/components/common/ActiveIcon.vue";
import TaxTypePreview from "@/modules/taxtypes/components/TaxTypePreview.vue";
import { canModuleAccess } from "@/services/moduleAccess";
import type FormFieldText from "@/components/form/fields/Text.vue";

const { math } = useMath();

@Component({
  components: {
    TaxTypePreview,
    ActiveIcon,
    ProductSelectOrCreate,
    InvoiceLabelsDialog,
    IconRemove,
    IconAdd,
    ProductSelect,
    ProductPreview,
    TableCell,
    ItemActions,
    UnitPriceField,
    InvoiceInlineDiscounts,
    ItemRowDetails,
    Loading,
  },
})
export default class ItemRow extends Vue {
  @VModel({ type: Object, default: () => new InvoicePosition() })
  item!: InvoicePosition;

  @Prop(Boolean) readonly disabled!: boolean;
  @Prop(Boolean) readonly canAdd!: boolean;
  @Prop(Boolean) readonly canDelete!: boolean;
  @Prop(Array) readonly headers!: DataTableHeader[];

  @Ref("productSelector") readonly obProductSelect!: ProductSelect;
  @Ref("quantityInput") readonly obQuantityInput!: FormFieldText;

  obProduct: Product | null = null;
  obTaxType: TaxType | null = null;
  obGlobalRate: CurrencyRate | null | undefined = null;
  dialogInlineDiscounts = false;
  dialogItemRowDetails = false;
  dialogLabels = false;
  fOldUnitPrice = 0;
  iKey = 0;
  loading = false;

  get arHeaderKeys() {
    return this.headers ? map(this.headers, "value") : [];
  }

  get invoice() {
    return InvoiceModule.invoice;
  }

  get positions() {
    return InvoiceModule.positions;
  }

  get exists() {
    return !!this.item.id && !!this.item.invoice_id;
  }

  get isReceived() {
    return InvoiceModule.isReceived;
  }

  /**
   * Get true if invoice is type BUY|PAY and has been processed
   */
  get isProcessed(): boolean {
    return this.isReceived && this.invoice.get("is_processed", false);
  }

  get isEditable() {
    return (
      this.exists &&
      this.isReceived &&
      !this.productIsRounding &&
      canModuleAccess(["accounting", "stocks"], true)
    );
  }

  get offer(): Offer | undefined {
    if (InvoiceModule.sTypeCode === InvoiceType.CODE_EREMITO) {
      return undefined;
    }

    const obOffer = this.item.get("offer", {});
    return obOffer instanceof Offer ? obOffer : new Offer(obOffer);
  }

  get product(): number {
    return this.offer ? this.offer.get("product_id", 0) : 0;
  }

  set product(iValue: number) {
    if (this.offer) {
      this.offer.set("product_id", iValue);
    }
  }

  get productIsRounding(): boolean {
    if (!this.obProduct) {
      return false;
    }

    const { obProduct } = useProduct(this.obProduct);

    return obProduct.isRounding;
  }

  get productIsTemporary() {
    if (!this.obProduct || !this.isReceived) {
      return false;
    }

    const { obProduct } = useProduct(this.obProduct);

    return obProduct.isTemporary;
  }

  get customer(): Partial<CustomerData> {
    return this.invoice.get("customer", {});
  }

  get sName(): string {
    return this.item.get("name", "");
  }

  set sName(sValue: string) {
    this.item.set("name", sValue);
  }

  get sProductName() {
    return this.obProduct
      ? `${this.obProduct.name} - ${this.obProduct.code}`
      : "";
  }

  get fTaxPercent(): number {
    return this.obTaxType ? this.obTaxType.percent : 0;
  }

  get refId(): number | null {
    return this.item.get("ref_id", null);
  }

  get unitPrice(): number {
    return this.item.get("unit_price", 0);
  }

  set unitPrice(fValue: number) {
    this.setUnitPrice(fValue);
  }

  /*
  get fUnitPrice() {
    if (!this.invoiceCurrency) {
      return this.unitPrice;
    }
    return currencyFormat(this.unitPrice, this.invoiceCurrency.code);
  }
*/

  get fPrice(): number {
    return number(this.item.get("price", 0));
  }

  get fPriceWithoutTax(): number {
    return this.item.get("price_without_tax");
  }

  get fPriceWithTax(): number {
    return this.item.get("price_with_tax");
  }

  get fUnitPriceWithoutTax(): number {
    // const sKey = this.exists ? "price_without_tax" : "unit_price_without_tax";
    let fUnitPrice = this.item.get("unit_price_without_tax", 0) as number;

    if (fUnitPrice) {
      return fUnitPrice;
    }

    fUnitPrice = this.unitPrice;

    return this.getPriceWithTax && this.fTaxPercent
      ? round(
          math.subtract(fUnitPrice, reverseTax(fUnitPrice, this.fTaxPercent))
        )
      : this.unitPrice;
  }

  get fUnitPriceWithTax(): number {
    return this.getPriceWithTax || !this.fTaxPercent
      ? this.unitPrice
      : round(
          math.add(this.unitPrice, percent(this.unitPrice, this.fTaxPercent))
        );
  }

  get originalPrice() {
    return number(this.item.get("original_price", 0));
  }

  set originalPrice(fValue: number) {
    this.item.set("original_price", fValue);

    if (this.offer) {
      this.offer.set("price_value", this.unitPrice);
    }
  }

  get unitPriceConverted() {
    return this.offer
      ? this.convert(this.offer.get(`${this.sPriceField}_value`)).then(
          (fValue) => fValue
        )
      : 0;
  }

  get fDiscount(): number {
    if (!this.discounts.length) {
      return 0;
    }

    return math.subtract(this.fUnitPriceWithoutTax, this.fPriceWithoutTax);
  }

  get sDiscount() {
    if (!this.invoiceCurrency || !this.fDiscount) {
      return 0;
    }

    let fValue = this.fDiscount;

    if (this.getPriceWithTax && this.fTaxPercent) {
      fValue = math.add(fValue, percent(fValue, this.fTaxPercent));
    }

    return currencyFormat(
      math.multiply(fValue, this.item.quantity),
      this.invoiceCurrency.code
    );
  }

  get fSubtotalWithoutTax() {
    return this.signed
      ? this.item.get("total_without_tax")
      : math.multiply(this.fPriceWithoutTax, this.item.quantity);
  }

  get sSubtotalWithoutTax() {
    if (!this.invoiceCurrency) {
      return this.fSubtotalWithoutTax;
    }

    return currencyFormat(this.fSubtotalWithoutTax, this.invoiceCurrency.code);
  }

  get fSubtotal(): number {
    if (this.signed) {
      const sField = this.getPriceWithTax
        ? "total_with_tax"
        : "total_without_tax";
      return number(this.item.get(sField, 0));
    }

    return math.multiply(
      this.getPriceWithTax && this.fTaxPercent
        ? this.fPriceWithTax
        : this.fPriceWithoutTax,
      this.item.quantity
    );
  }

  get sSubtotal() {
    if (!this.invoiceCurrency) {
      return this.fSubtotal;
    }

    return currencyFormat(this.fSubtotal, this.invoiceCurrency.code);
  }

  get fTotal(): number {
    return this.getPriceWithTax
      ? this.fSubtotal
      : math.sum([this.fSubtotal, this.taxPrice]);

    // const fValue = this.fPrice; // this.item.get("price") || this.unitPrice;
    //
    // if (!fValue || !this.item.quantity) {
    //   return 0;
    // }
    //
    // return fValue * this.item.quantity;
  }

  get sTotal() {
    if (!this.invoiceCurrency) {
      return this.fTotal;
    }

    return currencyFormat(this.fTotal, this.invoiceCurrency.code);
  }

  get taxPrice() {
    const fValue = 0;

    if (!this.fSubtotal || !this.fTaxPercent) {
      return fValue;
    }

    return percent(this.fSubtotal, this.fTaxPercent, true);
  }

  get fUnitPriceTax() {
    if (!this.unitPrice || !this.fTaxPercent) {
      return 0;
    }

    return percent(this.unitPrice, this.fTaxPercent, true);
  }

  get sTaxPrice() {
    if (!this.invoiceCurrency) {
      return this.taxPrice;
    }

    return currencyFormat(this.taxPrice, this.invoiceCurrency.code);
  }

  get taxPercent() {
    return this.fTaxPercent ? `${this.fTaxPercent}%` : "";
  }

  get rowClass() {
    return {
      "invoice-table-row": true,
      "invoice-table-row--edit": this.canAdd,
      "v-data-table__mobile-table-row": this.isMobile,
    };
  }

  get validRow(): boolean {
    return isNumber(this.fTotal) && !invalidItemPosition(this.item);
  }

  get showBtnReset() {
    if (!this.offer) {
      return false;
    }

    return this.unitPrice !== this.unitPriceConverted;
  }

  get currencies() {
    return AppModule.currencies;
  }

  get offerCurrency() {
    const iCurrencyId = this.offer ? this.offer.get("currency_id") : undefined;

    if (!iCurrencyId || !this.currencies || !this.currencies.length) {
      return undefined;
    }

    return this.currencies.find({ id: iCurrencyId });
  }

  get invoiceCurrency() {
    const iCurrencyId = this.invoice.get("currency_id");
    if (!iCurrencyId || !this.currencies || !this.currencies.length) {
      return undefined;
    }

    return this.currencies.find({ id: iCurrencyId });
  }

  get invoiceRate(): number {
    return this.invoice.get("rate", 0);
  }

  get createdAt(): string {
    return this.invoice.get("created_at");
  }

  get rateGlobal(): number {
    if (
      (!this.obGlobalRate || !this.obGlobalRate.rate) &&
      this.offerCurrency &&
      this.offerCurrency.rate
    ) {
      return number(this.offerCurrency.rate);
    }

    return this.obGlobalRate ? number(this.obGlobalRate.rate) : 0;
  }

  get rate() {
    if (!this.invoiceCurrency || !this.offerCurrency) {
      return this.rateGlobal;
    }

    const bInvert = this.invoiceCurrency.code !== "UYU";
    const fRateOffer = number(this.offerCurrency.rate);

    let fRate =
      bInvert &&
      this.rateGlobal &&
      this.rateGlobal !== 1 &&
      !this.useCustomCurrencies
        ? this.rateGlobal
        : this.invoiceRate;

    if (!bInvert && fRateOffer && fRateOffer !== 1) {
      fRate = fRateOffer;
    }

    return fRate;
  }

  get hint(): string | undefined {
    if (
      !this.offer ||
      !this.invoiceCurrency ||
      !this.offerCurrency ||
      this.invoiceCurrency.id === this.offerCurrency.id
    ) {
      return undefined;
    }

    const fPrice = this.offer.get(this.sPriceField, 0) as number;

    return fPrice && fPrice > 0
      ? (this.$t("original.price", {
          value: currencyFormat(fPrice, this.offerCurrency.code),
          rate: currencyFormat(this.rate, this.invoiceCurrency.code, 3),
        }) as string)
      : undefined;
  }

  get signed() {
    return InvoiceModule.signed || InvoiceModule.isSigning;
  }

  get company() {
    return AppModule.company;
  }

  get companySettings(): Partial<CompanySettingsData> {
    return this.company.get("settings");
  }

  get useCustomCurrencies(): boolean {
    return this.companySettings
      ? (get(this.companySettings, "use_custom_currencies", false) as boolean)
      : false;
  }

  get hasDiscountsPerLine(): boolean {
    return this.companySettings
      ? this.companySettings.discounts_per_line ?? false
      : false;
  }

  /**
   * Check if invoice use taxes in price
   */
  get getPriceWithTax(): boolean | undefined {
    return InvoiceModule.priceWithTax;
  }

  get sPriceField() {
    return this.getPriceWithTax ? "price_with_tax" : "price_without_tax";
  }

  get discounts(): DiscountDataExt[] {
    return this.item.get("discounts", []);
  }

  get iMovementType(): number {
    return this.invoice.get("invoice_movement_type_code", 0);
  }

  get isReturn() {
    const arMovementTypeList = [
      InvoiceMovementType.CODE_REFOUND,
      InvoiceMovementType.CODE_DEBIT_NOTE,
    ];
    return (
      !!this.invoice.customer_id &&
      arMovementTypeList.includes(this.iMovementType)
    );
  }

  /**
   * Check if current invoice is for sales
   * @returns {boolean}
   */
  get isSales(): boolean {
    const arMovementTypeList = [
      InvoiceMovementType.CODE_SALES,
      InvoiceMovementType.CODE_DEBIT_NOTE,
    ];
    return arMovementTypeList.includes(this.iMovementType);
  }

  get qtyMax(): number | null {
    return this.isReturn ? this.item.get("quantity_balance", null) : null;
  }

  get qtyRules() {
    if (this.signed) {
      return "";
    }

    const obRules = {
      required: true,
      double: true,
      is_not: 0,
    };

    if (this.qtyMax) {
      set(obRules, "max_value", this.qtyMax);
    }

    return obRules;
  }

  get unitPriceRules() {
    if (this.signed || !this.isReturn) {
      return {};
    }

    const obRules = {
      required: true,
      double: true,
      is_not: 0,
      max_value: this.item.price_balance,
    };

    return obRules;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  async mounted() {
    if (/*this.canAdd && */ !this.signed) {
      this.item.on("change", (obEvent: Record<string, InvoicePosition>) => {
        const obModel = obEvent.target;
        this.$emit("update", obModel);
      });
    }

    await this.onLoadProduct();
    this.focusProductSelect();
  }

  created() {
    EventBus.on("invoice.currency.rate.changed", this.onChangeCurrency);
    EventBus.on("invoice.price_list.changed", this.onChangePriceList);
    EventBus.on("invoice.change.price", this.applyInlineDiscounts);
  }

  beforeDestroy() {
    EventBus.off("invoice.currency.rate.changed", this.onChangeCurrency);
    EventBus.off("invoice.price_list.changed", this.onChangePriceList);
    EventBus.off("invoice.change.price", this.applyInlineDiscounts);
  }

  /**
   * Fetch the product from the API and then calling the onSelectProduct method.
   * @returns {void}
   */
  async onLoadProduct(): Promise<void> {
    await this.onLoadGlobalCurrencyRate();

    if (
      // this.loading ||
      (!this.product && !this.sName) ||
      (this.obProduct && this.obProduct.id === this.product)
    ) {
      return;
    }

    const obProduct = new Product();

    if (!this.product && this.sName) {
      obProduct.set("name", this.sName);
      obProduct.set("preview_text", this.item.get("description"));
      await this.onSelectProduct(obProduct);
      return;
    }

    this.loading = true;
    obProduct.set("id", this.product);
    await obProduct.fetch();

    if (
      (this.signed || this.exists) &&
      this.sName &&
      this.sName !== obProduct.name
    ) {
      obProduct.set("name", this.sName);
    }

    await this.onSelectProduct(obProduct);
    this.loading = false;
  }

  /**
   * After fetch product set item name/description, offer, taxes and inline discounts
   * @param {Product} obModel
   */
  async onSelectProduct(obModel: Product) {
    if (!this.exists && !this.invoice.id && !this.refId) {
      this.onResetAll();
    }

    this.obProduct = new Product({ id: obModel.id });
    await this.obProduct.fetch();

    if (!this.signed && !this.sName) {
      this.item.set("name", `${obModel.name} - ${obModel.code}`);
      this.item.set("description", obModel.preview_text);
    }

    // Load Taxtype model
    await this.onSetTaxtype();

    // Set offer item
    await this.onSetOffer(!this.signed && !this.exists && !this.refId);

    if (!this.item.quantity && !this.signed) {
      this.item.set("quantity", 1);
    }

    await this.applyInlineDiscounts();
    this.focusQuantity();
  }

  /**
   * Only for received invoices with temporary product, when update or create
   * product and be assigned to current position
   * @param {Product} obModel
   */
  async onChangeProduct(obModel: Product) {
    this.obProduct = obModel;
    const obOffer = first(obModel.offers);

    if (obOffer && obOffer.id) {
      this.loading = true;
      this.item.set("offer", obOffer);
      this.item.set("item_id", obOffer.id);
      await this.item.store();

      const obInvoiceModel = new Invoice({ id: this.invoice.id });
      await obInvoiceModel.fetch();

      if (obInvoiceModel.get("is_processable")) {
        this.invoice.set("is_processable", true);
      }

      this.loading = false;
    }
  }

  async onSetTaxtype() {
    if (this.signed) {
      const obItemTaxType = this.item.get("tax_type") as
        | TaxTypeData
        | undefined;
      if (obItemTaxType) {
        this.obTaxType = new TaxType(obItemTaxType);
      }
    }

    if (!this.obProduct) {
      return;
    }

    let obTaxType: TaxType | null | undefined = null;

    // Export movement use tax code 10
    if (this.iMovementType === 13) {
      const obTaxTypeCollection = new TaxTypeCollection();
      await obTaxTypeCollection.filterBy({ code: "10" }).fetch();

      obTaxType = obTaxTypeCollection.first();
    } else {
      if (this.customer?.tax_exempt) {
        const obTaxTypeCollection = new TaxTypeCollection();
        await obTaxTypeCollection.filterBy({ code: "1" }).fetch();

        obTaxType = obTaxTypeCollection.first();
      } else {
        const obPrdGW: ProductGwData = this.obProduct.get("product_gw");

        if (obPrdGW && obPrdGW.taxtype_id) {
          obTaxType = new TaxType({ id: obPrdGW.taxtype_id });
          await obTaxType.fetch();
        }
      }
    }

    if (!obTaxType || !obTaxType.id) {
      return;
    }

    this.item.set("tax_type_id", obTaxType.id);
    this.obTaxType = obTaxType;
  }

  async onSetOffer(force?: boolean) {
    if (!this.obProduct || (this.originalPrice && !force)) {
      return;
    }

    await this.onLoadGlobalCurrencyRate();

    // Check for current item offer and apply discounts if have
    if (this.refId || (this.item.id && this.offer && this.offer.id && !force)) {
      EventBus.emit("invoice.change.price");
      EventBus.emit("invoice.product.changed");
      this.applyDiscounts();

      return;
    }

    // Reset item offer
    this.item.set({
      price: 0,
      original_price: 0,
      unit_price: 0,
      price_with_discounts: 0,
      price_with_global_discounts: 0,
      offer: undefined,
    });
    this.fOldUnitPrice = 0;
    debug("Reset item data: ", this.item.attributes);

    const { obProduct } = useProduct(this.obProduct);
    let obOfferData: Partial<OfferData> | undefined =
      this.invoice && this.invoice.price_list_id
        ? obProduct.getOfferByListId(this.invoice.price_list_id)
        : obProduct.offer;

    if (!obOfferData) {
      obOfferData = obProduct.offer;
    }

    if (obOfferData) {
      const obOffer = new Offer(obOfferData);
      this.item.set("offer", new Offer(obOfferData));

      // Reset unit price
      if (this.unitPrice && !force) {
        this.originalPrice = this.unitPrice;
      }

      // Set price using price without tax applied
      const fPrice = obOffer.get(`${this.sPriceField}_value`);
      debug("Offer price: %s", fPrice);

      // Convert price if currency between invoice and offer are different
      const fValue = await this.convert(fPrice);
      debug("Converted price: %s", fValue);

      if (
        force ||
        (!this.item.id && (!this.item.unit_price || !this.item.price))
      ) {
        this.setUnitPrice(fValue);
      }

      this.item.set("original_price", fValue);

      if (force) {
        this.originalPrice = fValue;
      }
    }

    EventBus.emit("invoice.change.price");
    EventBus.emit("invoice.product.changed");
    this.applyDiscounts();
  }

  onChangeQty(fValue: number) {
    this.item.set("quantity", number(fValue));
    EventBus.emit("invoice.change.price");
  }

  openInlineDiscounts(closeMenu: Callback) {
    this.dialogInlineDiscounts = true;
    if (isFunction(closeMenu)) {
      attempt(closeMenu);
    }
  }

  openItemDetails(closeMenu: Callback) {
    this.dialogItemRowDetails = true;

    if (isFunction(closeMenu)) {
      attempt(closeMenu);
    }
  }

  openLabelsDialog(closeMenu: Callback) {
    this.dialogLabels = true;

    if (isFunction(closeMenu)) {
      attempt(closeMenu);
    }
  }

  async convertInlineDiscount(fPrice: number, obDiscount: DiscountDataExt) {
    if (obDiscount.fixed && obDiscount.percent && obDiscount.limit !== fPrice) {
      const fValue = percent(fPrice, obDiscount.percent, true, 3);
      const fLimit = fPrice;
      let fDisplayValue = fValue;
      fPrice = math.subtract(fPrice, fValue);

      if (this.getPriceWithTax && this.fTaxPercent) {
        fDisplayValue = round(
          math.add(fValue, percent(fValue, this.fTaxPercent))
        );
      }

      set(obDiscount, "name", fDisplayValue);
      set(obDiscount, "value", fValue);
      set(obDiscount, "limit", fLimit);
    }

    return fPrice;
  }

  async convertInlineDiscounts(bEmit = true) {
    if (!this.discounts.length) {
      this.loading = false;
      return;
    }

    this.loading = true;
    const arDiscountList = [...this.discounts];
    let fPrice = this.fUnitPriceWithoutTax;

    await arDiscountList.reduce(async (before, obDiscount) => {
      await before;
      fPrice = await this.convertInlineDiscount(fPrice, obDiscount);
    }, Promise.resolve());

    if (bEmit) {
      EventBus.emit("invoice.change.price");
    }

    this.loading = false;
  }

  /**
   * Apply inline discounts to current item
   *
   * @param {boolean} bSkipGlobalDiscounts Do not apply global discounts
   */
  async applyInlineDiscounts(bSkipGlobalDiscounts?: boolean) {
    await this.convertInlineDiscounts(false);

    if (this.refId && !this.discounts.length && !this.item.id) {
      delay(this.applyDiscounts, 500);
      return;
    }

    let fPrice = this.calculateInlineDiscount();
    this.item.set("price_without_tax", fPrice);

    if (fPrice && this.getPriceWithTax && this.fTaxPercent) {
      fPrice = math.add(fPrice, percent(fPrice, this.fTaxPercent));
    }

    debug("Applied discount: %s", fPrice);

    // this.item.set("price_with_discounts", fPrice);
    this.item.set("price", fPrice);
    this.item.set("price_with_tax", fPrice);

    if (bSkipGlobalDiscounts === true) {
      return;
    }

    delay(this.applyDiscounts, 500);
  }

  /**
   * Calculate inline discounts
   *
   * @param {number} fPrice
   * @param getDiscountOnly
   * @return {number}
   */
  calculateInlineDiscount(
    fPrice: number = 0,
    getDiscountOnly: Partial<DiscountData> | boolean = false
  ): number {
    if (!fPrice) {
      fPrice = this.fUnitPriceWithoutTax;
    }

    let fDiscounts = 0;

    forEach(this.discounts, (obDiscountData: DiscountDataExt | Discount) => {
      const obDiscount =
        obDiscountData instanceof Discount
          ? obDiscountData
          : new Discount(obDiscountData);
      const fPriceDiscount = obDiscount.calculate(fPrice);
      fDiscounts = math.add(fDiscounts, fPriceDiscount);
      fPrice = math.add(fPrice, fPriceDiscount);
      debug("Discount Price: %s", fPriceDiscount);
    });

    return getDiscountOnly ? fDiscounts : fPrice;
  }

  /**
   * Apply positions discounts to final price
   *
   * @date 11/11/2022 - 09:24:26
   * @author Planeta del Este
   */
  applyDiscounts() {
    EventBus.emit("invoice.apply.discounts");
  }

  /**
   * Create an empty line on top and set focus on product select
   */
  onAddItem() {
    if (!this.validRow || !this.canAdd) {
      return;
    }

    this.originalPrice = this.unitPrice;
    this.$emit("add:empty");

    this.focusProductSelect();
  }

  /**
   * Send "del" event with this.item value
   */
  onDeleteItem() {
    if (!this.canDelete) {
      return;
    }

    this.$emit("del", this.item);
  }

  onResetAll() {
    if (!this.obProduct || this.signed) {
      return;
    }

    this.item.set({
      price: 0,
      original_price: 0,
      unit_price: 0,
      price_with_discounts: 0,
      price_with_global_discounts: 0,
      offer: undefined,
      name: "",
    });
    this.fOldUnitPrice = 0;

    EventBus.emit("invoice.change.price");
    EventBus.emit("invoice.product.changed");
  }

  async onResetPrice() {
    if (this.exists || !this.offer || !this.offer.price_value) {
      return;
    }

    const fPrice = this.offer.get(`${this.sPriceField}_value`);
    const fValue = await this.convert(fPrice);
    this.setUnitPrice(fValue);
  }

  async onChangeCurrency() {
    if (!this.offer || !this.item.quantity || this.refId) {
      return;
    }

    this.loading = true;
    await this.onResetPrice();
    await this.convertInlineDiscounts();
  }

  async onChangePriceList() {
    // await this.onSetOffer(true);
  }

  reset() {
    this.onDeleteItem();
    this.onAddItem();
  }

  focusProductSelect() {
    if (
      !this.obProductSelect ||
      !this.canAdd ||
      this.invoice.id ||
      this.signed
    ) {
      return;
    }

    const elInput = this.obProductSelect.$el.querySelector("input");
    this.focusInput(elInput);
  }

  focusQuantity(): void {
    if (!this.obQuantityInput) {
      return;
    }

    const elInput = this.obQuantityInput.$el.querySelector("input");
    this.focusInput(elInput, 200);
  }

  focusInput(elInput: HTMLInputElement | null, wait: number = 500): void {
    if (!elInput) {
      return;
    }

    delay(() => {
      elInput.focus();
    }, wait);
  }

  /**
   * Convert fAmount to differente currency
   *
   * @param {Number} fAmount
   * @return {Promise<Number>}
   */
  async convert(fAmount: number): Promise<number> {
    if (
      !this.offerCurrency ||
      !this.invoiceCurrency ||
      this.invoiceCurrency.code == this.offerCurrency.code
    ) {
      return new Promise((resolve) => {
        resolve(fAmount);
      });
    }

    if (this.invoiceCurrency) {
      const sCode =
        this.invoiceCurrency.code === "UYU"
          ? this.offerCurrency.code
          : this.invoiceCurrency.code;
      const bInvert = this.invoiceCurrency.code !== "UYU";

      const obConvert = await currencyConvert(
        fAmount,
        sCode,
        this.rate,
        bInvert
      );
      if (obConvert) {
        return obConvert.value;
      }
    }

    return new Promise((resolve) => {
      const fValue = convertCurrency(
        fAmount,
        this.invoiceCurrency,
        this.offerCurrency
      );
      resolve(fValue);
    });
  }

  /**
   * Load BCU currency
   */

  async onLoadGlobalCurrencyRate() {
    if (!this.invoiceCurrency || !this.invoiceCurrency.external_id) {
      return;
    }

    const sDate = dayjs(this.createdAt).format("YYYY-MM-DD");
    const obCurrencyRateCollection = new CurrencyRates();
    // @ts-ignore
    await obCurrencyRateCollection
      .filterBy({
        rated: 1,
        taxed_at: sDate,
        currency: this.invoiceCurrency.id,
      })
      .fetch();

    this.obGlobalRate = obCurrencyRateCollection.first();
  }

  setUnitPrice(fValue: number) {
    fValue = number(fValue);

    if (fValue === this.item.unit_price && fValue === this.item.price) {
      return;
    }

    debug("SET UNIT PRICE", [fValue, this.item.unit_price, this.item.price]);

    if (fValue && !this.fOldUnitPrice) {
      this.fOldUnitPrice = fValue;
    }

    const fValueWithoutTax =
      this.getPriceWithTax && this.fTaxPercent
        ? round(math.subtract(fValue, reverseTax(fValue, this.fTaxPercent)))
        : fValue;

    this.item.set("unit_price", fValue);
    this.item.set("price", fValue);
    this.item.set("unit_price_without_tax", fValueWithoutTax);
    // this.item.set("price_with_discounts", fValue);

    EventBus.emit("invoice.change.price");

    debug("Unit price: %s", fValue);
  }

  @Watch("createdAt")
  async onChangeDate(): Promise<void> {
    if (this.obProduct) {
      await this.onSetOffer(true);
    } else {
      await this.onLoadGlobalCurrencyRate();
    }
  }
}
</script>
