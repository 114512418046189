<template>
  <module-container
    :title="$t('saved.invoices')"
    dense
    route-name="invoices.saved"
    route-name-create="invoices.create"
    show-toolbar
  >
    <template #route>
      <invoices-list filter-key="saved" unsigned />
    </template>

    <template #filters>
      <invoice-filters filter-key="saved" />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import InvoicesList from "@/modules/invoices/components/InvoicesList.vue";
import InvoiceFilters from "@/modules/invoices/components/InvoiceFilters.vue";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component({
  components: { InvoicesList, InvoiceFilters },
})
export default class InvoicesSaved extends Mixins(PaginateProviderMixin) {}
</script>
