<template>
  <gw-invoice-form
    v-if="bDisplayForm && obInvoice"
    :key="sKey"
    :hide-movement-type-select="hideMovementTypeSelect"
    :value="obInvoice"
    v-bind="$attrs"
  />
</template>

<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import InvoicesMixin from "@/modules/invoices/mixins/InvoicesMixin";
import InvoiceWithReferencesMixin from "@/modules/invoices/mixins/InvoiceWithReferencesMixin";

import GwInvoiceForm from "@/modules/invoices/components/InvoiceForm.vue";
import { InvoiceModule } from "@/store/invoice";
import type {
  CompanySettingsData,
  DiscountData,
  InvoiceData,
} from "@planetadeleste/vue-mc-gw";
import { Invoice, InvoiceMovementType } from "@planetadeleste/vue-mc-gw";
import { EventBus } from "@/services/event-bus";
import {
  assign,
  chain,
  cloneDeep,
  delay,
  find,
  get,
  pick,
  set,
  uniqueId,
} from "lodash";
import type { Channel } from "pusher-js";
import type { InvoiceCreatedChannel } from "@/types/broadcast";
import { AuthModule } from "@/store/auth";
import { usePusher } from "@/services/pusher";
import useInvoiceMovementType from "@/composables/invoiceMovementType";
import { number } from "mathjs";
import useInvoiceType from "@/composables/invoiceType";
import { AppModule } from "@/store/app";

@Component({
  components: { GwInvoiceForm },
})
export default class InvoiceForm extends Mixins(
  InvoicesMixin,
  InvoiceWithReferencesMixin
) {
  @Prop([String, Number]) readonly movementTypeId!: number;
  @Prop([String, Number]) readonly movementTypeCode!: number | string;
  @Prop([String, Number]) readonly invoiceTypeId!: number;
  @Prop([String, Number]) readonly invoiceTypeCode!: number | string;

  bDisplayForm = false;
  sKey = uniqueId();
  obInvoiceCreatedChannel: Channel | null = null;
  obInvoiceSigningChannel: Channel | null = null;
  obPusher = usePusher();

  get user() {
    return AuthModule.user;
  }

  get companySettings(): CompanySettingsData | undefined {
    return AppModule.companySettings;
  }

  get iCompanyCurrencyId(): number | null {
    return get(this.companySettings, "currency_id", null);
  }

  get sInvoiceTypeCode() {
    return InvoiceModule.routedTypeCode
      ? InvoiceModule.routedTypeCode
      : this.invoiceTypeCode;
  }

  onRegisterEvents() {
    this.addEvent(`${this.sModelName}.after.fetch`, () => {
      let obSourceItem;
      let bWasCorrected = false;

      // If are copying or correcting invoice remove some properties
      if (
        this.$route.name &&
        (this.$route.name.includes("copy") ||
          this.$route.name.includes("correct"))
      ) {
        obSourceItem = cloneDeep(this.obItem.attributes) as InvoiceData;
        this.obItem.unset([
          "id",
          "is_signed",
          "sign_at",
          "order_serial",
          "order_number",
          "referenced",
          "references",
        ]);
        this.obItem.set("references", []);

        if (this.$route.name.includes("correct")) {
          bWasCorrected = true;
        }
      } else if (
        this.$route.name &&
        ["invoices.view", "invoices.debt.view"].includes(this.$route.name)
      ) {
        this.obItem.set("in_view", true);
      }

      this.obItem.sync();
      InvoiceModule.setInvoice(this.obItem.attributes);

      if (bWasCorrected && obSourceItem) {
        this.addReferences([obSourceItem]);
      }

      this.showFormDelayed();
    });

    this.addEvent(
      `${this.sModelName}.after.save`,
      async (obData: InvoiceData) => {
        EventBus.emit("company.reload.balance");

        // Set route for emitted or saved invoices
        let sRouteName = "create"; // get(obData, "is_signed") ? "emitted" : "saved";
        const obParams: Record<string, any> = {};
        const sMovementTypeCode = number(
          get(obData, "invoice_movement_type.code", 0)
        );

        // Set route for debt invoices (cobranzas)
        if (
          [
            InvoiceMovementType.CODE_DEBT,
            InvoiceMovementType.CODE_CANCEL_DEBT,
            InvoiceMovementType.CODE_DEBT_RYA,
          ].includes(sMovementTypeCode)
        ) {
          sRouteName = `debt.${sRouteName}`;
        }

        // Set route for receipt invoices (pago a proveedores)
        if (InvoiceMovementType.CODE_PAY === sMovementTypeCode) {
          sRouteName = `receipt.${sRouteName}`;
        }

        if (this.$route.name === `invoices.${sRouteName}`) {
          this.emit("reload.form");

          return;
        }

        await this.$router.push({
          name: `invoices.${sRouteName}`,
          params: obParams,
        });
        this.loaded();
      }
    );

    this.addEvent("reload.form", () => {
      this.obItem = new Invoice();
      InvoiceModule.setInvoice(this.obItem);
      this.setInitInvoiceProps();
      this.sKey = uniqueId();
    });

    // Pusher channels
    this.obInvoiceCreatedChannel = this.obPusher.channel(
      "invoice",
      "created",
      this.onCreateOrSign
    );
    this.obInvoiceSigningChannel = this.obPusher.channel(
      "invoice",
      "signing",
      this.onCreateOrSign
    );
  }

  async onCreateOrSign(obData: InvoiceCreatedChannel) {
    if (obData.user_id !== this.user.id) {
      return;
    }

    this.loaded();

    if (obData.message) {
      this.$toast.success(obData.message);
    }

    if (obData.signing) {
      InvoiceModule.addSign(obData.invoice_id);
    }

    // if (this.$route.name && this.$route.name !== "invoices.update") {
    //   await this.$router.push({
    //     name: "invoices.update",
    //     params: { id: toString(obData.invoice_id) },
    //   });
    // }
  }

  onBeforeDestroy() {
    if (this.obInvoiceCreatedChannel) {
      this.obInvoiceCreatedChannel.disconnect();
    }

    if (this.obInvoiceSigningChannel) {
      this.obInvoiceSigningChannel.disconnect();
    }

    InvoiceModule.setInvoice(new Invoice());
    InvoiceModule.setTaxesAmount([]);
  }

  showFormDelayed(): void {
    delay(() => {
      this.bDisplayForm = true;
    }, 300);
  }

  async setInitInvoiceProps() {
    if (this.companySettings?.addenda) {
      this.obInvoice.set("description", this.companySettings.addenda);
    }

    // Set currency ID
    if (this.iCompanyCurrencyId) {
      this.obInvoice.set("currency_id", this.iCompanyCurrencyId);

      // Set round value to Invoice model
      if (this.companyCurrencyPivot) {
        let fRound = this.companyCurrencyPivot.round;

        if (fRound === "0.00" || !fRound) {
          fRound = 1;
        }

        this.obInvoice.set("round", fRound);
      }
    }

    // Set movement by ID or CODE
    let sInvoiceTypeCode = this.movementTypeCode
      ? number(this.movementTypeCode)
      : 0;

    // Set default movement type to code "1" - Sell
    if (!this.movementTypeId && !this.movementTypeCode) {
      sInvoiceTypeCode = 1;
    }

    if (
      InvoiceModule.routedMovementTypeCode.length &&
      !InvoiceModule.routedMovementTypeCode.includes(sInvoiceTypeCode)
    ) {
      sInvoiceTypeCode = 0;
    }

    if (this.movementTypeId) {
      const { items } = await useInvoiceMovementType();
      const obMovementTypeItem = find(items, { id: this.movementTypeId });

      if (obMovementTypeItem) {
        this.obInvoice.set("invoice_movement_type_id", this.movementTypeId);
        this.obInvoice.set(
          "invoice_movement_type_code",
          obMovementTypeItem.code
        );
      }
    } else if (sInvoiceTypeCode) {
      const { items } = await useInvoiceMovementType(sInvoiceTypeCode);

      if (items.length) {
        this.obInvoice.set("invoice_movement_type_id", items[0].id);
        this.obInvoice.set("invoice_movement_type_code", sInvoiceTypeCode);
      }
    }

    // Set invoice type ID
    if (this.invoiceTypeId) {
      this.obInvoice.set("invoice_type_id", this.invoiceTypeId);
    } else if (this.sInvoiceTypeCode) {
      const { items } = await useInvoiceType();
      const obInvoiceTypeItem = find(
        items,
        (obItem) => number(obItem.code) === number(this.sInvoiceTypeCode)
      );

      if (obInvoiceTypeItem) {
        this.obInvoice.set("invoice_type", obInvoiceTypeItem);
        this.obInvoice.set("invoice_type_id", obInvoiceTypeItem.id);
      }
    }

    if (
      this.$route.name &&
      ["invoices.view", "invoices.debt.view"].includes(this.$route.name)
    ) {
      this.obInvoice.set("in_view", true);
    }
  }

  onMounted() {
    if (!this.$route.params.id) {
      if (!(this.obItem instanceof Invoice)) {
        this.obItem = new Invoice();
      }

      this.obItem.sync();
      InvoiceModule.setInvoice(this.obItem.attributes);
      this.setInitInvoiceProps();
      this.showFormDelayed();
      return;
    }

    if (
      this.$route.name &&
      this.$route.name.includes("pay") &&
      this.$route.params.id
    ) {
      this.cancelInvoice();
    }
  }

  onExtendRouteChanged(arValue: string[]) {
    arValue.push("invoices.receipt.create");
    arValue.push("invoices.receipt.update");
    arValue.push("invoices.received.view");
    arValue.push("invoices.debt.create");
    arValue.push("invoices.debt.update");
    arValue.push("invoices.debt.view");
    arValue.push("invoices.correct");

    return arValue;
  }

  cancelInvoice() {
    const obData: Partial<InvoiceData> = {
      invoice_movement_type_id: 2,
      created_at: this.$dayjs().format("YYYY-MM-DD"),
    };

    const obInvoice = new Invoice({ id: number(this.$route.params.id) });
    obInvoice.fetch().then((response) => {
      if (response && response.getData()) {
        const obInvoiceData = response.getData().data;
        const arPickAttrs = [
          "customer_id",
          "branch_id",
          "company_id",
          "company",
          "currency_id",
          "currency",
          "customer",
          "price_list_id",
          "rate",
          "discounts",
        ];
        assign(obData, pick(obInvoiceData, arPickAttrs));
        if (obData.discounts && obData.discounts.length) {
          const arDiscounts = chain(obData.discounts)
            .filter((obDiscount: DiscountData) => obDiscount.value > 0)
            .map((obDiscount) => {
              set(obDiscount, "id", null);
              return obDiscount;
            })
            .value();
          set(obData, "discounts", arDiscounts);
        }
        this.obItem = this.defaultModel(obData);

        InvoiceModule.setInvoice(this.obItem);
        this.addReferences([obInvoiceData]);
        this.showFormDelayed();
      }
    });
  }
}
</script>
