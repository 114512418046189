/*eslint no-unsafe-finally: "off"*/

import Vue from "vue";
import type { RouteConfigLocal } from "@/router/routes";
import { AppModule } from "@/store/app";
import type { ProfileData, UserData } from "@planetadeleste/vue-mc-shopaholic";
import { Currency } from "@planetadeleste/vue-mc-shopaholic";
import {
  attempt,
  chain,
  cloneDeep,
  get,
  has,
  isEqual,
  isFunction,
  isInteger,
  isNil,
  isObjectLike,
  isPlainObject,
  isString,
  join,
  mapValues,
} from "lodash";
import type { Result } from "vue-mc";
import type { AxiosResponse } from "axios";
import { GwCacheModule } from "@/store/cache";
import { ceil, number, round as mathRound } from "mathjs";
import { debug } from "@/composables/debug";
import type { Route } from "vue-router";
import type VueRouter from "vue-router";
import type { RouteNamedMap } from "@/types/typed-router";

export type DebounceFunction = () => Promise<void> | undefined | void;
export type RoundByPosition = 0 | 0.1 | 0.01 | 0.001 | 1 | 10 | 100;

export interface CurrencyConvertResponse {
  amount: number;
  value: number;
  format: string;
  code: string;
}

/**
 * @description Convert string to dotCase
 */
export const dotcase = (sValue: any) => {
  return chain(sValue).trim().kebabCase().replace(/-/g, ".").value();
};

/**
 * Convert sValue to code name
 *
 * @param {String} sValue
 * @returns {String}
 */
export const toCode = (sValue: string) => {
  return chain(sValue)
    .trim()
    .deburr()
    .words()
    .filter((sVal) => sVal.length > 2)
    .map((sVal, skey, arItems) => {
      if (arItems.length > 3) {
        return sVal.charAt(0);
      }
      return sVal.slice(0, 3);
    })
    .join("")
    .toUpper()
    .value();
};

/**
 *
 * @param {RouteConfigLocal} route
 * @param {UserData|ProfileData} obUser
 * @returns Boolean
 */
export const routeAccess = (
  route: RouteConfigLocal | Route,
  obUser: UserData | ProfileData
): boolean => {
  const obPerms = get(route, "meta.permissions");

  if (!obPerms) {
    return true;
  }

  const obMatched = chain(obPerms)
    .filter((item) => item.role == obUser.role)
    .first()
    .value();

  return obMatched ? get(obMatched, "access", false) : true;
};

export const getRoute = (
  sName: keyof RouteNamedMap,
  $router: VueRouter
): Route | RouteConfigLocal => {
  return $router.resolve({ name: sName }).route;
};

/**
 * Round a number
 * @param {Number|String} sValue
 * @param {Number} iDecimal
 * @returns {Number}
 */
export const round = (
  sValue: number | string,
  iDecimal: number = 2
): number => {
  sValue = number(sValue);

  return mathRound(sValue, iDecimal);
};

/**
 * Count decimal positions of a number
 * @param {number} sValue
 */
export const countDecimals: (sValue: number) => number = (sValue: number) => {
  if (isInteger(sValue)) {
    return 0;
  }

  const arValue = sValue.toString().split(".");

  return arValue.length >= 2 ? arValue[1].length : 0;
};

/**
 * Fix values with smaller decimal positions, like 1.009
 * @param {number} sValue
 */
export const fixFloat = (sValue: number): number => {
  const arValue = sValue.toString().split(".");

  if (arValue.length < 2 || arValue[1].length < 2) {
    return sValue;
  }

  const sDecimals = arValue[1];

  if (sDecimals[0] === "0" && sDecimals[1] === "0") {
    return round(sValue, 0);
  }

  return round(sValue);
};

/**
 * Round value by position. Where position can be 0|0.1|0.01|1|10|100
 * @param {Number|String} sValue
 * @param {Number} iPosition
 * @returns {Number}
 */
export const roundBy = (
  sValue: number | string,
  iPosition: RoundByPosition = 0
): number => {
  sValue = number(sValue);
  iPosition = number(iPosition) as RoundByPosition;

  if (!sValue || !iPosition) {
    return sValue;
  }

  // round to integer
  if (iPosition === 1) {
    return round(sValue, 0);
  }

  // Round by decimal
  if (iPosition < 1 && iPosition > 0) {
    const iDecimal = iPosition.toString().split(".")[1].length;
    return round(sValue, iDecimal);
  }

  // Round up by integer
  if (iPosition > 1) {
    return ceil(sValue / iPosition) * iPosition;
  }

  return sValue;
};

/**
 * Calculate percent of fValue
 * @param {Number} fValue Input value
 * @param {Number} fPercent Percent value
 * @param {Boolean} rounded Set to true to round result
 * @param {number} iDecimal
 * @returns {Number}
 */
export const percent = (
  fValue: number,
  fPercent: number,
  rounded: boolean = false,
  iDecimal: number = 2
): number => {
  const fResult = (fValue * number(fPercent)) / 100;

  return rounded ? round(fResult, iDecimal) : fResult;
};

/**
 * Calculate what percentage of `fValueTwo` is `fValueOne`
 * @param {Number} fValueOne
 * @param {Number} fValueTwo
 * @param {Boolean} rounded
 * @param {number} iDecimal
 * @returns {Number}
 */
export const getPercent = (
  fValueOne: number,
  fValueTwo: number,
  rounded: boolean = false,
  iDecimal: number = 2
): number => {
  const fResult = (100 / fValueTwo) * fValueOne;

  return rounded ? round(fResult, iDecimal) : fResult;
};

/**
 * Calculate total value of applied percent to fValue
 *
 * @param {Number} fValue
 * @param {Number} fPercent
 * @param {Boolean} rounded
 * @param {number} iDecimal
 * @returns {Number}
 */
export const getTotalOfAppliedPercent = (
  fValue: number,
  fPercent: number,
  rounded: boolean = false,
  iDecimal: number = 2
): number => {
  const fResult = (100 * fValue) / fPercent;

  return rounded ? round(fResult, iDecimal) : fResult;
};

/**
 * Calculate reverse tax value
 * @param {number} fValue Input value
 * @param {number} fTax Tax value
 * @param {boolean} rounded Set true to round result
 * @param {number} iDecimal
 * @returns {number}
 */
export const reverseTax = (
  fValue: number,
  fTax: number,
  rounded: boolean = false,
  iDecimal: number = 2
): number => {
  const fResult = fValue / (1 + 100 / fTax);

  return rounded ? round(fResult, iDecimal) : fResult;
};

/**
 * Convert value to negative
 * @param {number} fValue
 */
export const negative = (fValue: number) => {
  return fValue > 0 ? -Math.abs(fValue) : fValue;
};

/**
 * Convert value to positive
 * @param {number} fValue
 */
export const positive = (fValue: number) => {
  return fValue < 0 ? fValue * -1 : fValue;
};

/**
 * Convert -0 value to 0
 * @param {number} fValue
 */
export const cleanNumber = (fValue: number) => {
  return Object.is(round(fValue), -0) ? 0 : fValue;
};

/**
 *
 * @param {string|number} sValue
 * @param {Currency|number} obCurrencyTo
 * @param {Currency|number} obCurrencyFrom
 * @param {number} iDecimal
 * @returns Number
 */
export const convertCurrency = (
  sValue: string | number,
  obCurrencyTo?: Currency | number,
  obCurrencyFrom?: Currency | number,
  iDecimal: number = 2
) => {
  sValue = number(sValue);

  if (!obCurrencyFrom) {
    obCurrencyFrom = AppModule.defaultCurrency;
  }

  if (!obCurrencyFrom || !obCurrencyTo) {
    return round(sValue, iDecimal);
  }

  const fRateFrom: number =
    obCurrencyFrom instanceof Currency
      ? obCurrencyFrom.get("rate_value")
      : obCurrencyFrom;
  const fRateTo: number =
    obCurrencyTo instanceof Currency
      ? obCurrencyTo.get("rate_value")
      : obCurrencyTo;

  if (fRateFrom == fRateTo) {
    return round(sValue, iDecimal);
  }

  const fResultPrice = (fRateFrom * sValue) / fRateTo;
  return round(fResultPrice, iDecimal);
};

/**
 *
 * @param sValue
 * @param sBaseCurrencyCode
 * @param fRate
 * @param bReverse
 * @returns {Promise<CurrencyConvertResponse>}
 */
export const currencyConvert = async (
  sValue: string | number,
  sBaseCurrencyCode: string,
  fRate?: number | string,
  bReverse = false
): Promise<CurrencyConvertResponse | undefined> => {
  const $axios = Vue.axios;
  const baseUrl = "gw/currencyrates/convert";
  const arParams: any[] = [sValue, sBaseCurrencyCode];
  if (!isNil(fRate)) {
    arParams.push(fRate);
  }
  if (bReverse) {
    arParams.push(1);
  }

  const obResponse: AxiosResponse<Result<CurrencyConvertResponse>> =
    await $axios.get(`${baseUrl}/${join(arParams, "/")}`);

  if (obResponse && obResponse.data) {
    return obResponse.data.data;
  }

  return undefined;
};

/**
 *
 * @param {number} sValue
 * @param {string} sCode
 * @param {number} iDecimalPositions
 * @param {boolean} bWithoutSymbol
 * @returns String | Number
 */
export const currencyFormat = (
  sValue: number,
  sCode?: string,
  iDecimalPositions: number = 2,
  bWithoutSymbol: boolean = false
) => {
  if (isNil(sCode)) {
    return sValue;
  }

  const obNumber = new Intl.NumberFormat("es-UY", {
    style: "currency",
    currency: sCode,
    minimumFractionDigits: iDecimalPositions,
  });

  if (bWithoutSymbol) {
    const fCurrencyFractionDigits =
      obNumber.resolvedOptions().maximumFractionDigits;
    return sValue.toLocaleString("es-UY", {
      maximumFractionDigits: fCurrencyFractionDigits,
      minimumFractionDigits: iDecimalPositions,
    });
  }

  return obNumber.format(sValue);
};

/**
 * Mask credit card number, showing only last four digits
 * @param {String} sNumber
 * @returns String
 */
export const maskCC = (sNumber: string): string => {
  let first12 = sNumber.slice(0, 12);
  const char = "*";
  const repeatedChar = char.repeat(sNumber.length - 4);
  first12 = first12.replace(first12, repeatedChar);
  return first12 + sNumber.slice(sNumber.length - 4);
};

export const deepToJson = (
  obData: Record<string, any>
): Record<string, any> => {
  if (
    isObjectLike(obData) &&
    !isPlainObject(obData) &&
    has(obData, "attributes")
  ) {
    return deepToJson(get(obData, "attributes"));
  }

  if (isObjectLike(obData) && !isNil(obData)) {
    return mapValues(obData, (obItem) => {
      return isObjectLike(obItem) ? deepToJson(obItem) : obItem;
    });
  }

  return obData;
};

export const trimLines = (sValue: string) => {
  return sValue
    .split(/\r?\n/)
    .filter((line) => line.trim() !== "")
    .join("\n");
};

/**
 * Performs a `SameValueZero` comparison between two values to determine if they are equivalent.
 * @param {Object} obValue
 * @param {Object} obOther
 * @returns {boolean}
 */
export const eqJson = (
  obValue: Record<string, any> | string,
  obOther: Record<string, any> | string
): boolean => {
  let isEq = false;

  try {
    const sOne = isObjectLike(obValue) ? cloneDeep(obValue) : obValue;
    const sTwo = isObjectLike(obOther) ? cloneDeep(obOther) : obOther;
    isEq = isEqual(sOne, sTwo);

    return isEq;
  } catch (error) {
    debug([obValue, obOther]);
    console.error(error);
  }

  return isEq;
};

export const cache = (
  obData: Record<string, any> | CallableFunction | number | string,
  sKey?: string,
  sIdentifier?: string
) => {
  if (!sIdentifier && sKey) {
    if (sKey.includes(".")) {
      const arKeyList = sKey.split(".");
      sKey = arKeyList[0];
      sIdentifier = arKeyList[1];
    } else {
      sIdentifier = sKey;
    }
  }

  if (isString(obData)) {
    sKey = obData;
  }

  if (!sIdentifier) {
    sIdentifier = "unknown";
  }

  if (!sKey) {
    sKey = "unknown";
  }

  const sCacheKey = `${sKey}.${sIdentifier}`;

  if (isFunction(obData)) {
    const obResponseData = attempt(obData);
    GwCacheModule.set({ [sCacheKey]: obResponseData });
  } else if (isObjectLike(obData)) {
    GwCacheModule.set({ [sCacheKey]: obData });
  }

  return get(GwCacheModule.cache, sCacheKey);
};
