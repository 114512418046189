import type {
  CurrencyRate,
  InvoiceData,
  InvoiceMovementTypeData,
  TaxType,
} from "@planetadeleste/vue-mc-gw";
import {
  Invoice,
  InvoiceCollection,
  InvoiceMovementType,
} from "@planetadeleste/vue-mc-gw";
import type { AxiosRequestConfig } from "axios";
import { route } from "@/services/laroute";
import { AppModule } from "@/store/app";
import { ConfigModule } from "@/store/config";
import {
  find,
  forEach,
  get,
  isBoolean,
  isFunction,
  isNil,
  isNumber,
  isString,
  pick,
} from "lodash";
import type { RoundByPosition } from "@/plugins/helpers";
import {
  cleanNumber,
  currencyFormat,
  negative,
  round,
  roundBy,
} from "@/plugins/helpers";
import dayjs from "dayjs";
import type { CounterResponse, InvoiceTaxTypeList } from "@/types/utils";
import { number } from "mathjs";
import printJs from "print-js-updated";
import Utils from "@/services/Utils";
import { FlashModule } from "@/store/flash";
import type { Result } from "vue-mc";
import type { ResponseType } from "axios/index";
import { UAParser } from "ua-parser-js";

export class InvoiceHelper {
  private readonly _invoice: Invoice;
  private reportCurrencyId = 0;
  private taxes: TaxType[] = [];

  constructor(obInvoice: InvoiceData | Invoice) {
    this._invoice =
      obInvoice instanceof Invoice ? obInvoice : new Invoice(obInvoice);
  }

  get invoice(): Invoice {
    return this._invoice;
  }

  /**
   * Output report currency
   */
  get reportCurrency() {
    if (!this.reportCurrencyId) {
      return AppModule.defaultCurrency;
    }

    return AppModule.currencies.find({ id: this.reportCurrencyId });
  }

  get currency() {
    if (!this.invoice.currency_id) {
      return AppModule.defaultCurrency;
    }

    return AppModule.currencies.find({ id: this.invoice.currency_id });
  }

  get currencyRate(): CurrencyRate | undefined {
    if (!ConfigModule.companyCurrencyRates.length) {
      return undefined;
    }

    const iCurrencyId: number | null = this.reportCurrency
      ? this.reportCurrency.id
      : this.currency
      ? this.currency.id
      : null;

    return iCurrencyId
      ? ConfigModule.companyCurrencyRates.find({ currency_id: iCurrencyId })
      : undefined;
  }

  get obMovementType(): InvoiceMovementTypeData | undefined {
    return this.invoice.get("invoice_movement_type");
  }

  get increase(): boolean {
    return this.obMovementType
      ? get(this.obMovementType, "increase_balance", true)
      : true;
  }

  get taxValueList(): InvoiceTaxTypeList[] {
    return this.invoice.get("tax_value_list", []);
  }

  get fSubtotal() {
    return round(this.invoice.get("total_price_without_tax_value", 0), 3);
  }

  get fTaxValue() {
    return round(this.invoice.get("total_tax_value", 0), 3);
  }

  get fTotal() {
    return round(this.fSubtotal + this.fTaxValue, 3);
  }

  /**
   * Get rounded total value
   *
   * @date 21/10/2022 - 08:34:17
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fTotalRounded() {
    let fRound = this.fRoundBy;

    if (
      this.currencyRate &&
      this.currencyRate.round &&
      this.iMovementType === InvoiceMovementType.CODE_SALES
    ) {
      fRound = this.currencyRate.round as RoundByPosition;
    }

    return roundBy(this.fTotal, fRound);
  }

  /**
   * Get rounding value
   *
   * @date 21/10/2022 - 08:35:40
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fRoundValue(): number {
    return this.fTotalRounded - this.fTotal;
  }

  get iMovementType(): number {
    return number(this.invoice.get("invoice_movement_type.code", 0));
  }

  /**
   * Get currency setting of round by value
   *
   * @date 21/10/2022 - 08:30:47
   * @author Planeta del Este
   *
   * @readonly
   * @type {number}
   */
  get fRoundBy(): RoundByPosition {
    return this.invoice.get("round", 1) || 1;
  }

  get createdAt(): string {
    return dayjs(this.invoice.created_at).format("L");
  }

  get signAt(): string {
    return dayjs(this.invoice.sign_at).format("L");
  }

  get totalPriceValue(): string | number {
    return this.getCurrencyFormat(this.fTotalRounded);
  }

  get roundedPriceValue(): string | number {
    let fValue = cleanNumber(this.fRoundValue);

    forEach(this.taxValueList, (obTaxItem: InvoiceTaxTypeList) => {
      const obTax = find(this.taxes, { code: obTaxItem.code });
      if (!number(obTax?.percent)) {
        fValue += obTaxItem.total_amount;
      }
    });

    return this.getCurrencyFormat(fValue);
  }

  get rate(): string | number {
    return this.getCurrencyFormat(this.invoice.get("rate_convert", 0));
  }

  static async listByType<T = any>(
    sType: string,
    iCompanyId: number | null = null
  ): Promise<T> {
    const obInvoice = new Invoice();
    const obData: Record<string, any> = { type: sType };

    if (iCompanyId) {
      obData.company_id = iCompanyId;
    }

    const obConfig: AxiosRequestConfig = {
      url: route("invoices.list_type", obData),
      method: "GET",
      data: obData,
    };
    const obResponse = await obInvoice.createRequest(obConfig).send();

    return obResponse.getData().data;
  }

  setReportCurrencyId<T extends InvoiceHelper>(this: T, fValue: number): T {
    this.reportCurrencyId = fValue;

    return this;
  }

  setTaxes<T extends InvoiceHelper>(this: T, arValue: TaxType[]): T {
    this.taxes = arValue;

    return this;
  }

  /**
   * Get value in currency format, without symbol
   *
   * @param {number} fValue
   * @param {string} sCode
   */
  getCurrencyFormat(fValue: number, sCode?: string | number): string | number {
    if (fValue && !this.increase && fValue > 0) {
      fValue = negative(fValue);
    }

    if ((!this.reportCurrency && !sCode) || !fValue) {
      return fValue;
    }

    if (!sCode && this.reportCurrency) {
      sCode = this.reportCurrency.code;
    }

    if (isNumber(sCode)) {
      const obCurrency = AppModule.currencies.find({ id: sCode });
      if (obCurrency) {
        sCode = obCurrency.code;
      }
    }

    return sCode && isString(sCode)
      ? currencyFormat(fValue, sCode, 2, true)
      : fValue;
  }

  /**
   * Update invoice payment methods
   */
  async updatePaymentMethods(): Promise<InvoiceData | undefined> {
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.update_paymentmethods", { id: this.invoice.id }),
      method: "POST",
      data: pick(this.invoice.attributes, ["id", "payment_methods"]),
    };
    const obResponse = await this.invoice.createRequest(obConfig).send();

    return obResponse.getData().data;
  }

  /**
   * Set processed received invoice
   */
  async updateProcessed(): Promise<Result<InvoiceData | undefined>> {
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.update_processed", { id: this.invoice.id }),
      method: "POST",
    };
    const obResponse = await this.invoice.createRequest(obConfig).send();

    return obResponse.getData();
  }

  async copy(): Promise<number | undefined> {
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.copy", { id: this.invoice.id }),
      method: "POST",
    };
    const obResponse = await this.invoice.createRequest(obConfig).send();

    return obResponse.getData()?.data as number | undefined;
  }

  async refund(): Promise<number | undefined> {
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.refund", { id: this.invoice.id }),
      method: "POST",
    };
    const obResponse = await this.invoice.createRequest(obConfig).send();

    return obResponse.getData()?.data as number | undefined;
  }

  async charge(): Promise<number | undefined> {
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.charge", { id: this.invoice.id }),
      method: "POST",
    };
    const obResponse = await this.invoice.createRequest(obConfig).send();

    return obResponse.getData()?.data as number | undefined;
  }

  async rebuildReceived(): Promise<number | undefined> {
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.rebuild_xml", { id: this.invoice.id }),
      method: "POST",
    };
    const obResponse = await this.invoice.createRequest(obConfig).send();

    return obResponse.getData()?.data as number | undefined;
  }

  async cancel(reason: string): Promise<number | undefined> {
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.cancel", { id: this.invoice.id }),
      method: "POST",
      data: { reason },
    };
    const obResponse = await this.invoice.createRequest(obConfig).send();

    return obResponse.getData()?.data as number | undefined;
  }
}

export default function useInvoice(obInvoice?: Invoice | InvoiceData) {
  const listByType = async <T = any>(
    sType: string,
    iCompanyId: number | null = null
  ): Promise<T> => {
    return await InvoiceHelper.listByType<T>(sType, iCompanyId);
  };

  const obj = obInvoice ? new InvoiceHelper(obInvoice) : undefined;

  const count: (
    obData?: Record<string, any>
  ) => Promise<undefined | CounterResponse> = async (
    obData: Record<string, any> = {}
  ) => {
    obData = { filters: obData };
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.count", obData),
      method: "GET",
    };
    const obCollection = new InvoiceCollection();

    return (await obCollection
      .createRequest(obConfig)
      .send()) as CounterResponse;
  };

  /**
   * Get invoice PDF content
   * @param {string|number} uuid
   */
  const loadContent = async (uuid: string | number) => {
    const obData: Record<string, any> = { uuid };
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.print", obData),
      method: "GET",
      data: obData,
      responseType: "blob" as ResponseType,
    };
    const obInvoice = new Invoice();
    const obResponse = await obInvoice.createRequest(obConfig).send();
    return obResponse.response ? obResponse.response.data : null;
  };

  /**
   * Print invoice PDF
   * @param {string|number} uuid
   * @param {string} [filename]
   * @param {CallableFunction} [cb]
   */
  const print = async (
    uuid?: string | number,
    filename?: string,
    cb?: CallableFunction
  ) => {
    if (!uuid) {
      return;
    }

    const ua = UAParser();

    if (ua.device.type) {
      await openPdf(obj?.invoice.transaction_id, filename, cb);
      return;
    }

    const sContent = await loadContent(uuid);

    if (!sContent) {
      return;
    }

    const obReader = new FileReader();
    obReader.readAsDataURL(sContent);
    obReader.onloadend = () => {
      printJs({ base64: true, printable: obReader.result, type: "pdf" });
      if (cb) {
        cb();
      }
    };
  };

  /**
   * Print invoice PDF
   * @param {string|number} uuid
   * @param {string} [filename]
   * @param {CallableFunction} [cb]
   */
  const downloadPdf = async (
    uuid?: string | number,
    filename?: string,
    cb?: CallableFunction
  ) => {
    await openOrDownloadPdf(uuid, filename, cb);
  };

  /**
   * Open invoice PDF in new tab
   *
   * @param uuid
   * @param filename
   * @param cb
   */
  const openPdf = async (
    uuid?: string | number,
    filename?: string,
    cb?: CallableFunction
  ) => {
    await openOrDownloadPdf(uuid, filename, cb, true);
  };

  /**
   * Print invoice PDF
   *
   * @param uuid
   * @param filename
   * @param cb
   * @param open
   */
  const openOrDownloadPdf = async (
    uuid?: string | number,
    filename?: string,
    cb?: CallableFunction | boolean,
    open?: boolean
  ) => {
    try {
      if (!uuid) {
        return;
      }

      if (isBoolean(cb)) {
        open = cb;
        cb = undefined;
      }

      if (open) {
        Utils.openUrl(`/i/pdf/${uuid}`);
      } else {
        const sContent = await loadContent(uuid);

        if (!filename) {
          filename = uuid as string;
        }

        if (!isNil(sContent)) {
          Utils.downloadBlob(sContent, `${filename}.pdf`);
        }
      }

      if (isFunction(cb)) {
        cb();
      }
    } catch (e: any) {
      console.error(e);

      if (e.response) {
        let obResponse = await e.response.response.data.text();
        if (isString(obResponse)) {
          obResponse = JSON.parse(obResponse);
          if (obResponse.message) {
            FlashModule.error(obResponse.message);
          }
        }
      }
    }
  };

  /**
   * Get invoice XML content
   * @param {string|number} uuid
   * @returns {Promise<string | undefined>}
   */
  const xml = async (uuid?: string | number): Promise<string | undefined> => {
    if (!uuid) {
      return;
    }

    const obData = { uuid };
    const obConfig: AxiosRequestConfig = {
      url: route("invoices.xml", obData),
      method: "GET",
      data: obData,
    };
    const obInvoice = new Invoice();
    const obResponse = await obInvoice.createRequest(obConfig).send();

    return obResponse.response ? get(obResponse, "response.data.data") : null;
  };

  /**
   * Download invoice XML
   * @param {string|number} uuid
   */
  const downloadXml = async (uuid?: string | number, filename?: string) => {
    try {
      const sContent = await xml(uuid);

      if (!filename) {
        filename = uuid as string;
      }

      if (!isNil(sContent)) {
        Utils.downloadBlob(sContent, `${filename}.xml`);
      }
    } catch (e: any) {
      console.error(e);

      if (e.response) {
        let obResponse = await e.response.response.data.text();
        if (isString(obResponse)) {
          obResponse = JSON.parse(obResponse);
          if (obResponse.message) {
            FlashModule.error(obResponse.message);
          }
        }
      }
    }
  };

  return { listByType, obj, count, print, xml, downloadXml, downloadPdf };
}
